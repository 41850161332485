import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { format as DateFnsFormat } from 'date-fns'

const BlogCreated = styled.span`
  font-style: italic;
  color: #aaaaaa;
  font-size: 0.9em;
`

const BlogTag = styled.button`
  margin-bottom: 5px;
  margin-right: 5px;

  &:hover {
    color: #ad0025;
    text-decoration: underline;
  }
`

const DATE_FORMAT = 'MMMM d, yyyy'

export default function Blog (props) {
  const { title, created, body, tags } = props


  let tagList = null
  if (tags) {
    tagList = (
      <p>
        {tags.map((tag, idx) => {
          return (
            <BlogTag key={idx} className="uk-button uk-button-default">{tag}</BlogTag>
          )
        })}
      </p>
    )
  }

  const createdDate = new Date(created * 1000)

  return (
    <React.Fragment>
      <h1>{title}</h1>
      <div className="uk-grid">
        <div className="uk-width-medium-1-2 uk-width-small-1-1">
          <BlogCreated>{DateFnsFormat(createdDate, DATE_FORMAT)}</BlogCreated>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: body }} className="blog-body" />
      {tagList}
    </React.Fragment>
  )
}

Blog.propTypes = {
  title: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
}
